<template>
  <div v-if="campaign">
    <v-alert :value="!campaign.published_at" class="mb-4" type="warning">
      Kladde
    </v-alert>

    <v-alert
      :value="campaign && campaign.status.name === 'ended'"
      class="mb-4"
      type="error"
    >
      Campaign is completed
    </v-alert>

    <campaign-tabs v-model="campaign" class="elevation-1"></campaign-tabs>
  </div>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers.js";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import CampaignTabs from "../../Tabs/CampaignTabs/CampaignTabs";
import { mapActions } from "vuex";

export default {
  components: { CampaignTabs },
  mixins: [search_filters_url],
  filters: { local_numbers },
  data: () => ({
    campaign: null
  }),
  methods: {
    ...mapActions("core/campaigns", ["loadCampaign"]),
    getCampaign() {
      const params = {
        uuid: this.$route.params.id
      };
      this.loadCampaign(params).then(
        campaign => {
          this.campaign = campaign;
        },
        () => {
          this.setSnackError("Fejl");
        }
      );
    }
  },
  created() {
    this.getCampaign();
  }
};
</script>
